<template>
  <div>
    <div class="capitalize text-gray-700 font-inter text-sm font-medium flex mb-1.5 w-full">
      <span>{{ heading }}</span>
      <span v-if="rules.includes('required')" class="text-text-color-danger pl-1">*</span>
    </div>
    <Field
      v-model="dateTimeModelValue"
      :rules="rules"
      :label="title"
      :name="name"
      as="div"
      class="flex border rounded-lg divide-x"
      :class="isErrorAvailable && 'border-error-500 bg-error-50 divide-error-500'"
    >
      <UiDatePicker
        v-model="date"
        class="flex-1"
        :hide-title="true"
        :is-date-time="true"
        :is-error-bg-color="isErrorBgColor"
      />
      <UITimePicker
        v-model="time"
        class="flex-1"
        :hide-title="true"
        :name="`${name}-Time`"
        :is-date-time="true"
      />
    </Field>
    <div class="w-full">
      <div ref="errorContainer" class="flex justify-end h-5">
        <ErrorMessage v-slot="{ message }" :name="name">
          <small class="error-msg text-text-color-danger text-sm italic">
            <p>{{ displayError(message) }}</p>
          </small>
        </ErrorMessage>
      </div>
    </div>
  </div>
</template>

<script>
import UiDatePicker from '@components/UiElements/UiDatePicker.vue'
import UITimePicker from '@/src/components/UiElements/UiTimePicker.vue'
import { getDate, getTime } from '@src/utils/generalUtil.js'
import { getTimeFromDateWithUTC } from '@utils/moment.util'
import { mapGetters } from 'vuex'
import validationMixin from '@src/mixins/components/validation-mixin.js'

import { Field, configure, ErrorMessage } from 'vee-validate'
import { validationConfiguration } from '@src/vee-validate/index.js'
configure(validationConfiguration)

export default {
  components: {
    UiDatePicker,
    UITimePicker,
    Field,
    ErrorMessage,
  },
  mixins: [validationMixin],
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  props: {
    name: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: 'Date-Time',
    },
    rules: {
      type: String,
      default: '',
    },
    modelValue: {
      type: String,
      default: '',
    },
    currentDate: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      date: '',
      time: '',
      dateTime: '',
      isErrorAvailable: false,
    }
  },
  computed: {
    ...mapGetters('layout', ['campusTimeZone']),
    dateTimeModelValue() {
      return this.date && this.time
    },
    isErrorBgColor() {
      return this.isErrorAvailable ? 'ltr:bg-bg-error' : ''
    },
    heading() {
      return this.name ? this.title : this.$t(`title.${this.title}`)
    },
  },
  watch: {
    date: {
      handler() {
        this.updatedValue()
      },
    },
    time: {
      handler() {
        this.updatedValue()
      },
    },
  },
  created() {
    if (this.currentDate) this.date = new Date()
    if (this.modelValue) {
      this.time = getTime(this.modelValue)
      this.date = getDate(this.modelValue)
    }
  },
  methods: {
    updatedValue() {
      const date = this.date
      const time = this.time
      this.dateTime = `${date}T${time}`
      this.$emit('update:modelValue', this.dateTime)
    },
  },
}
</script>
